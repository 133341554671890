.card {
  border: 1px solid #111;
  margin: 10px 0;
  padding: 20px;
}

.div {
  display: flex;
  flex-direction: column;
}

.screenshot {
  width: 1400px;

  margin-left: auto;
  margin-right: auto;

  border-radius: 40px;
  border: 10px solid black;
}

.main {
  /* height: 900px; */
  display: flex;
  align-items: start;
  justify-content: space-evenly;
  /* gap: 31px; */
  align-self: stretch;
  margin-top: 16px;
  margin-bottom: 60px;
}

.header1 {
  display: flex;
  flex-direction: row;

  justify-content: space-between;
}

.headerbutton1 {
  display: flex;
  padding: 24px 28px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 15px;
  background: #5864ff;
}

.headerbutton1text {
  color: #fff;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.2px;
}

.headerbutton2 {
  display: flex;
  padding: 24px 28px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  background-color: black;

  border-radius: 15px;
  border: 2px solid #f3f3f3;
}

.headerbutton2pic {
  width: 24px;
  height: 24px;
}

.headerbutton2text {
  color: #fff;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.2px;
}

.buttons {
  display: flex;
  flex-direction: row;
  height: 24px;
  gap: 30px;

  margin-right: 100px;
  margin-top: 40px;
}

.background {
  display: flex;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  background: url("./res/bg.svg") #101010;

  color: white;
}
/* .background_main{
  width: 100%;
  height: 100%;
} */

.big-logo{
  height: 80%;
  width: 80%;
  margin-top: 120px;
  margin-bottom: auto;
}

.logo {
  width: 127px;
  height: 132.198px;
  flex-shrink: 0;

  margin-left: 137px;
  margin-top: 15px;
}

.bodydiv1 {
  display: flex;
  width: 840px;
  height: 560px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;

  margin-left: 140px;
  margin-top: 100px;
}

.bodydiv2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}

.bodyupperbutton {
  display: flex;
  padding: 14px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  background-color: transparent;
  border-radius: 15px;
  border: 2px solid #5864ff;
}

.bodyupperbuttontext {
  color: #5864ff;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.44px;
}

.bodydiv3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  align-self: stretch;
}

.bodydiv3text1 {
  align-self: stretch;

  color: #fff;
  font-family: Roboto;
  font-size: 74px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 4.44px;
}

.bodydiv3text2 {
  align-self: stretch;

  color: #fff;
  font-family: Roboto;
  font-size: 38px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.bodylowerbutton {
  display: flex;
  width: 368px;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;

  border-radius: 15px;
  background: #5864ff;
}

.bodylowerbuttontext {
  color: #fff;
  font-family: Roboto;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.68px;
}

.bodylowerbuttonpic {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
}

.maindiv {
  padding: 90px 64px;
}

.div1 {
  display: flex;
  padding: 32px;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;

  border-radius: 30px;
  background: #fff;
}

.div1pic {
  width: 90px;
  height: 90px;
}

.div1_1text1 {
  align-self: stretch;

  color: #000;
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.div1_1text2 {
  align-self: stretch;

  color: #000;
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.div2 {
  display: flex;
  /* height: 658px; */
  gap: 24px;
  align-items: start;
}

.div2_1 {
  display: flex;
  width: 557.333px;
  height: 515px;
  padding: 44px 32px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  margin-top: 54px;

  border-radius: 40px;
  background: #5764ff;
}

.div2_1pic {
  width: 180px;
  height: 180px;
  flex-shrink: 0;
}

.div2_1text1 {
  align-self: stretch;

  color: #fff;
  font-family: Roboto;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.div2_1text2 {
  align-self: stretch;

  color: #fff;
  font-family: Roboto;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.div2_2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
}

.div2_2_1 {
  display: flex;
  padding: 44px 42px;
  align-items: center;
  gap: 32px;
  flex: 1 0 0;
  align-self: stretch;

  margin-top: 55px;

  border-radius: 40px;
  background: #fff;
}

.div2_2_1pic {
  width: 140px;
  height: 140px;
}

.div2_1text {
  display: flex;
  flex-direction: column;
}

.div2_2_1text1 {
  align-self: stretch;

  color: #000;
  font-family: Roboto;
  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.div2_2_1text2 {
  align-self: stretch;

  color: #000;
  font-family: Roboto;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.div2_2_2 {
  display: flex;
  padding: 44px 42px;
  align-items: center;
  gap: 32px;
  flex: 1 0 0;
  align-self: stretch;

  /* margin-top: 200px; */

  border-radius: 40px;
  background: #fff;
}

.div2_2_2pic {
  width: 140px;
  height: 140px;
}

.div2_2_2text1 {
  align-self: stretch;

  color: #000;
  font-family: Roboto;
  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.div2_2_2text2 {
  align-self: stretch;

  color: #000;
  font-family: Roboto;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.header {
  /* width: 100%; */
  display: flex;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;

  justify-items: center;
  justify-self: center;
  align-items: center;
  align-content: center;
  justify-content: space-around;
  margin: 0 25px;
}

.button {
  display: flex;
  padding: 10px 12px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  /* flex: 1 0 0; */
  border-radius: 20px;
  border: 2px solid #000;
  background-color: #ebeefd;
  flex: 1;
}

.button.active {
  display: flex;
  padding: 12px 14px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;

  border: 2px #000;
  border-radius: 20px;
  background: #5764ff;
}

.btnpic {
  width: 32px;
  height: 32px;
}

.button.active .btnpic {
  filter: brightness(0) invert(1);
  width: 32px;
  height: 32px;
}

.btnname {
  color: black;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

button.active .btnname {
  color: white;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.block1 {
  display: flex;
  width: 33%;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;

  border-radius: 40px;
  /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); */
}

.block1_1 {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;

  border-radius: 40px;
}

.block1_2 {
  display: flex;
  height: 100px;
  padding: 44px;
  flex-direction: column;
  align-items: flex-start;
  gap: 498px;
  align-self: stretch;

  border-radius: 40px;
  background: #fff;
}

.block1_2_2 {
  display: flex;
  height: 109px;
  padding: 35px;
  flex-direction: column;
  align-items: flex-start;
  align-items: center;
  gap: 498px;
  align-self: stretch;

  border-radius: 40px;
  background: #fff;
}

.block1_2_inner {
  align-self: stretch;

  color: #000;
  font-family: Roboto;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.block1_2_2_inner {
  align-self: stretch;

  color: #000;
  font-family: Roboto;
  font-size: 27px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.block1_2_4_inner {
  align-self: stretch;

  color: #000;
  font-family: Roboto;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.block2 {
  display: flex;
  width: 33%;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}

.block2_1 {
  display: flex;
  padding: 44px;
  flex-direction: column;
  align-items: flex-start;
  gap: 498px;
  align-self: stretch;

  border-radius: 40px;
  background: #fff;
}

.block2_1_4 {
  display: flex;
  padding: 46px;
  flex-direction: column;
  align-items: flex-start;
  gap: 498px;
  align-self: stretch;

  border-radius: 40px;
  background: #fff;
}

.block2_1_inner {
  align-self: stretch;

  color: #000;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.block2_2 {
  height: 602px;
  display: flex;
  align-items: center;
  align-self: stretch;

  border-radius: 40px;
  background: #fff;
}

.block2_2_2 {
  height: 639px;
  display: flex;
  align-items: center;
  align-self: stretch;

  border-radius: 40px;
  background: #fff;
}

.block3 {
  width: 407px;
  height: 33%;
  flex-shrink: 0;

  border-radius: 40px;
  background: #fff;
}
